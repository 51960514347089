<template>
  <div>
    <h2 :style="dynamicStyle" @click="change">{{ msg }}</h2>
  </div>
</template>

<script>
export default {
  name: 'HelloWelcome',
  props: {
    msg: String
  },
  data() {
    return {
      dynamicStyle: {
        color: 'red'
      },
      style1: {
        color: 'red'
      },
      style2: {
        color: 'blue'
      }
    }
  },
  methods: {
    change() {
      if (this.compareObj(this.dynamicStyle, this.style1)) {
        this.dynamicStyle = this.style2
      } else {
        this.dynamicStyle = this.style1
      }
    },
    compareObj(a, b) {
      return JSON.stringify(a) == JSON.stringify(b) ? true: false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  color: #12b983;
}
</style>
