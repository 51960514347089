<template>
  <HelloWelcome msg="Welcome to Peony's Home~"/>
  <img src="./assets/peony.jpeg" :style=fullWidth>
</template>

<script>
import HelloWelcome from './components/Welcome.vue'

export default {
  name: 'App',
  components: {
    HelloWelcome,
  },
  data() {
    return {
      fullWidth : {
        width : document.documentElement.clientWidth+'px'
      }
    }
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
